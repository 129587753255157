var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "usual-flow-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.flowList, function (item, index) {
          return _c(
            "el-col",
            {
              key: "usual_" + index,
              staticClass: "usual-column",
              attrs: { span: 12 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "usual-item",
                  on: {
                    click: function ($event) {
                      return _vm.jumpItem(item)
                    },
                  },
                },
                [
                  _c("a", { attrs: { href: "javascript:void(0)" } }, [
                    _vm._v(_vm._s(item.displayName)),
                  ]),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }