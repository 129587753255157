













import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import HomePage from '@/api/home/homePage';
import DateUtil from '@/utils/DateUtil';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
@Component({
  name: 'FlowMonitor'
})
export default class FlowCenter extends Vue {
  homePage: HomePage = new HomePage();
  monitorList: any[] = [];
  mounted() {
    this.homePage.getMonitor({ page: { pageIndex: 1, pageSize: 5 } }).then(res => {
      let today = new Date();
      this.monitorList = res.data.list.map(a => {
        a.date = this.formateDate(a.dueDate);
        let due = new Date(a.dueDate);
        a.isOver = today > due;
        return a;
      });
    });
  }
  jumpItem(row) {
    let url = process.env.VUE_APP_SERVER_CODE + 'task/redirect/undo';
    let params = '?id=' + row.id + '&token=' + TokenUtil.getToken();
    window.location.href = url + params;
  }
  formateDate(val) {
    if (val) {
      let date = DateUtil.parse(val);
      return DateUtil.format(date, 'yyyy-MM-dd');
    }
  }
}
