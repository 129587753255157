var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "main-page" },
    [
      _c("el-main", [
        _c("div", { staticClass: "layout-row" }, [
          _c(
            "div",
            { staticClass: "layout-left" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "none",
                    "border-bottom": "3px solid #7f7f7f",
                    margin: "18px 20px 0 20px",
                  },
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "layout-title",
                      staticStyle: {
                        float: "left",
                        "border-bottom": "none",
                        margin: "0",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_process_center")))]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        float: "right",
                        color: "red",
                        cursor: "pointer",
                        height: "33px",
                        "line-height": "24px",
                        "font-size": "var(--rootFontSize3)",
                        "font-family": "Source Han Sans CN",
                        "font-weight": "bold",
                      },
                      on: { click: _vm.goToFlowInit },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "25px",
                          height: "25px",
                          padding: "0",
                          "vertical-align": "bottom",
                        },
                        attrs: {
                          src: require("../../assets/image/icon/add.png"),
                        },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("lang_Initiating_process"))),
                      ]),
                    ]
                  ),
                ]
              ),
              _c("flow-center"),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "layout-right" },
            [
              _c("h2", { staticClass: "layout-title" }, [
                _vm._v(_vm._s(_vm.$t("lang_calendar_schedule"))),
              ]),
              _c("calendar-block"),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "layout-row" }, [
          _c(
            "div",
            { staticClass: "layout-left" },
            [
              _c("h2", { staticClass: "layout-title" }, [
                _vm._v(_vm._s(_vm.$t("lang_common_processes"))),
              ]),
              _c("usual-flow"),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "layout-right" },
            [
              _c("h2", { staticClass: "layout-title" }, [
                _vm._v(_vm._s(_vm.$t("lang_event_monitoring"))),
              ]),
              _c("flow-monitor"),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }