var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitor-container" },
    _vm._l(_vm.monitorList, function (item) {
      return _c(
        "div",
        {
          key: item.id,
          class: {
            "monitor-row": true,
            "orange-row": !item.isOver,
            "red-row": item.isOver,
          },
          on: {
            click: function ($event) {
              return _vm.jumpItem(item)
            },
          },
        },
        [
          _c("p", { staticClass: "monitor-date" }, [
            _vm._v("限办日期: " + _vm._s(item.date)),
          ]),
          _c("p", { staticClass: "monitor-detail" }, [
            _vm._v(_vm._s(item.title)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }