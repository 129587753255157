











import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import HomePage from '@/api/home/homePage';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
@Component({
  name: 'UsualFlow'
})
export default class FlowCenter extends Vue {
  homePage: HomePage = new HomePage();
  token: string = TokenUtil.getToken();
  flowList: any[] = [];
  jumpItem(row) {
    let url = process.env.VUE_APP_SERVER_CODE + 'task/redirect/create';
    let params = '?processId=' + row.id + '&token=' + TokenUtil.getToken();
    window.location.href = url + params;
  }
  // 获取常用流程
  getFactoryProcess() {
    this.homePage.getFactoryProcess({ count: 8 }).then(res => {
      if (res.code == '1') {
        if (res.data) {
          this.flowList = res.data.list;
        } else {
          this.flowList = [];
        }
      } else {
        this.$message.error(res.message);
      }
    });
  }
  mounted() {
    this.getFactoryProcess();
  }
}
