






import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import HomePage from '@/api/home/homePage';
import DateUtil from "@/utils/DateUtil"
@Component({
  name: 'CalendarBlock',
  components: {
    Calendar: () => import('../calendar/calendar.vue')
  }
})
export default class FlowCenter extends Vue {
  homePage: HomePage = new HomePage();
  markList: any[] = [];
   mounted(){
      this.homePage.getMonitor({page: {pageIndex: 1,pageSize: 5}}).then(res=>{
          let today = new Date()
          this.markList = res.data.list.map(a=>{
              a.date = this.formateDate(a.dueDate)
              let due = new Date(a.dueDate)
              a.className = today>due?"red-date":"yellow-date";
              return {className: a.className,date: a.date}
          })
      })
  }
   formateDate(val) {
    if (val) {
      let date = DateUtil.parse(val)
      return DateUtil.format(date,"yyyy-MM-dd")
    }
  }
}
