













































import FlowCenter from './components/flowCenter.vue';
import CalendarBlock from './components/calendarBlock.vue';
import FlowMonitor from './components/flowMonitor.vue';
import UsualFlow from './components/usualFlow.vue';
import { Component, Vue } from 'vue-property-decorator';
// import '@/utils/easy-pop/easyPop';

@Component({
  name: 'indexPage',
  components: {
    FlowCenter,
    CalendarBlock,
    FlowMonitor,
    UsualFlow
  }
})
export default class extends Vue {
  /**
   * 跳转到发起流程页面
   */
  goToFlowInit() {
    this.$router.push({
      path: '/flowInit'
    });
  }
}
